<template>
  <div class="container mt-4">
    <div class="text-center" v-if="loading">
      <loader />
    </div>
    <div v-else>
      <div v-if="form && allowSubmit">
        <form-preview :form="form"></form-preview>
      </div>
      <div v-if="!allowSubmit" class="formFinished">
        <h1>{{ form.title }}</h1>
        <h2>{{ 'forms.notAllowedToSubmit' | translate }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import FormPreview from '@forms/components/FormPreview.vue';
import Vue from 'vue';

export default {
  components: {
    FormPreview
  },
  data() {
    return {
      form: null,
      loading: true,
      allowSubmit: true
    };
  },
  created() {
    const user = JSON.parse(window.localStorage.getItem('onboarding_user'));

    Vue.prototype.$http
      .get(
        `/dashboard/v2/forms/${this.$route.params.moduleId}/${this.$route.params.learningpageId}/${this.$route.params.formId}?languageId=${user.languageId}`
      )
      .then(
        (response) => {
          this.loading = false;
          if (response.data.message && response.data.message === 'Published form version was not found') {
            return;
          }
          if (response.data.formSubmit && !response.data.editAfterSubmission) {
            this.allowSubmit = false;
          }
          this.form = response.data;
        },
        () => {
          this.$notify({
            type: 'error',
            message: this.$t('notifications.errorGettingForm'),
            position: 'bottom-right'
          });
          this.loading = false;
        }
      );
  }
};
</script>

<style lang="scss" scoped>
.formFinished {
  text-align: center;
}
</style>
